@import "../../global.scss";

.home{
  height: 100%;
}

.home__module{
  width: 800px;
  max-width: 800px;
  min-width: 200px;

  &-item{
    padding: 60px;
    transition: all 0.2s;
    cursor: pointer;
  }

  &-item:hover{
    scale: 1.05;
  }
}

