@import "../../../global.scss";

.contact-request{
  width: 100%;
  background: rgba(44, 45, 47, 0.64);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(18.3px);
  -webkit-backdrop-filter: blur(18.3px);
  border: 1px solid rgba(44, 45, 47, 0.72);
}