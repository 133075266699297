@import "../../../../global.scss";

.core__block{
  transition: all 0.2s;
  // height: 120px;
  padding: 30px;
  cursor: pointer;
};

.core__block:hover{
  scale: 1.02;
}