@import "../../global.scss";

.auth{
  height: 100vh;

  padding-top: 80px;
}

.auth__background{
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
  object-fit: cover;

  filter: blur(2px);
}

.auth__dackdrop{
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -2;
  object-fit: cover;

  background-color: black;
}

.auth__top{
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  padding: 15px 0px;

  background: rgba(44, 45, 47, 0.64);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(18.3px);
  -webkit-backdrop-filter: blur(18.3px);
  border: 1px solid rgba(44, 45, 47, 0.72); 
}


.auth__content{
  width: 100%;
  height: 60vh;

  background: rgba(44, 45, 47, 0.64);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(18.3px);
  -webkit-backdrop-filter: blur(18.3px);
  border: 1px solid rgba(44, 45, 47, 0.72);
}

.auth__text{
  overflow: hidden;
  height: 0px; 
  color: $primary-dark!important;
  text-align: center;
}

