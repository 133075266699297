@import "../../../global.scss";

.business{
    height: 100%;
}
  
.business__module{
    width: 800px;
    max-width: 800px;
    min-width: 200px;
  
    &-item{
      padding: 40px;
      transition: all 0.2s;
      cursor: pointer;
    }
  
    &-item:hover{
      scale: 1.05;
    }
  }
  
  