* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body{
  font-family: 'Urbanist', sans-serif;
}

html { scroll-behavior: smooth; } 

.Toastify__toast {
  border-radius: 16px;
  font-weight: 400;
  font-size: 14px;
  color: #f1f1f1;
  font-family: "Urbanist", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}