@import "../../global.scss";

.nav{
  background-color: black;
  color: $primary-light;

  width: 100%;
  height: 100vh;
  min-height: 100vh;
}

.nav__topbar{
  width: 100%;
  height: 72px;

  padding: 0px 20px;

  // background: rgba(44, 45, 47, 0.64);
  // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(18.3px);
  // -webkit-backdrop-filter: blur(18.3px);
  // border: 1px solid rgba(44, 45, 47, 0.72);
}

.nav__top-module{
  gap: 10px;
  
  padding: 5px;
  background: rgba(44, 45, 47, 0.64);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(18.3px);
  -webkit-backdrop-filter: blur(18.3px);
  border: 1px solid rgba(44, 45, 47, 0.72);
  border-radius: 16px;
}

.nav__side{
  &-container{
    width: 100%;
    height: fit-content;
    background: rgba(44, 45, 47, 0.64);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(18.3px);
    -webkit-backdrop-filter: blur(18.3px);
    border: 1px solid rgba(44, 45, 47, 0.72);
    border-radius: 16px;

    overflow: hidden;
  }

  &-item{
    gap: 10px;
    padding: 12px 15px;
    cursor: pointer;
  }

  &-item:hover{
    background-color: rgba(65, 66, 70, 0.64);
  }

  &-selected{
    gap: 10px;
    padding: 12px 15px;
    background-color: $secondary-light;
  }

  &-common{
    overflow: auto;
    height: 100%;
    padding: 12px 0px 12px 15px;
    // background: rgba(44, 45, 47, 0.64);
  }
}