@import "./theme/colors";
@import "./theme/breakpoints";
@import "./theme/spacing";

@mixin fullWidthHeight {
    width: 100%;
    height: 100%;
}

.fullWidthHeight {
    width: 100vw;
    height: 100vh;
}

//  -------------- flex box with DIRECTION-------------
@mixin flexDirection($direction) {
    display: flex;
    flex-direction: $direction;
}

@mixin flexCenterCenterDirection($direction) {
    display: flex;
    flex-direction: $direction;
    align-items: center;
    justify-content: center;
}

@mixin flexCenter_Direction($direction) {
    display: flex;
    flex-direction: $direction;
    align-items: center;
}

@mixin flex_CenterDirection($direction) {
    display: flex;
    flex-direction: $direction;
    justify-content: center;
}

@mixin flexCenterSBDirection($direction) {
    display: flex;
    flex-direction: $direction;
    align-items: center;
    justify-content: space-between;
}

// ------------ flex box without DIRECTION
@mixin flexCenterCenter() {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flexCenter_Row(){
    display: flex;
    flex-direction: row;
    align-items: center;
}

@mixin flexCenterSB(){
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@mixin flexCenterSBColumn(){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

//  ---------- dividers-----------------
.divider{
    background-color: rgb(36, 35, 35);
    width: 1px;
    height: 200px;
    margin: 0px 3vw 0px 3vw;
}

.horizontal-divider{
    background-color: rgb(184, 184, 184);
    height: 1px;
    width: 100%;
}


// ---------- global classes -----------

.flexCenter_Row{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.grid_2items{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.grid_3items{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.flexCenterSBRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.flexCenterSERow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}


.flexFSSBRow{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.flexFESBRow{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.flexCenterEndRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}


.flexCenterCenterRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.flex_FEColumn{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.flex_FSColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.flex_SBColumn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flexCenterSEColumn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.flexCenterFERow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.flexCenterSBColumn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.flexFE_Column{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.flexFEFEColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}


.flex_CenterColumn{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flexCenterCenterColumn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.longText{
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
}

.modal{
    background: rgba(44, 45, 47, 0.64);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(18.3px);
    -webkit-backdrop-filter: blur(18.3px);
    border: 1px solid rgba(44, 45, 47, 0.72);

    border-radius: 16px;
    width: 100%;
}

.block{
    width: 100%;
    background: rgba(44, 45, 47, 0.64);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(18.3px);
    -webkit-backdrop-filter: blur(18.3px);
    border: 1px solid rgba(44, 45, 47, 0.72);
}

.table__clickable-row:hover{
    background-color: rgba(44, 45, 47, 1);
}

//---------- export to use in js file --------
:export{
    // colors
    white: $white;
    black: $black;
    
    primary_light: $primary-light;
    primary: $primary;
    primary_dark: $primary-dark;

    secondary_light: $secondary-light;
    secondary: $secondary;
    secondary_dark: $secondary-dark;

    // flags
    success: $success;
    error: $error;
    warning: $warning;

    // text
    black_text: $black-text;
    main_text: $main-text;
    sub_text: $sub-text;
}